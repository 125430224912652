.resPage {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    padding: 0 10%;
}

.tool-card {
    width: 23%;
    height: 65%;
}

.ant-card-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.resPage.analysis {
    background-color: rgba(183, 203, 119, 0.73);
}

.resPage.formulation {
    background-color: rgba(72, 149, 175, 0.54);
}

.resPage.collection {
    background-color: rgba(124, 181, 105, 0.79);
}

.resPage.presentation {
    background-color: rgba(144, 82, 87, 0.78);
}

.substage {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}

.an-interpretation {
    width: 100%;
    height: 15%;
}

.substage-card {
    background-color: rgba(255, 255, 255, 0.8);
    margin-top: 20px;
}

.substage-title {
    height: 100%;
    width: 10px;
    word-wrap: break-word;
    text-align: center;
    align-self: center;
    font-weight: 800;
    font-size: 1.5em;
}

.substage-3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    height: 25%;
    margin-top: 20px;
}

.substage-3 .substage-card {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: start;
}

.tool-card-s {
    width: 90%;
}

.group-horizontal {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.group-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.excel {
    font-size: 0.9em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #005b36;
}

.substage-2 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 20%;
    margin-top: 20px;
}

.substage-2 .substage-card {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.pr-development .tool-card {
    margin-left: 20px;
}

.pr-writing .tool-card {
    margin-left: 20px;
}