.landing {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #282c34;
}

.title {
    color: azure;
    font-family: FZQ, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 5em;
    font-weight: 300;
    margin-top: 50px;
}

.phases i {
    --arrow-size: 16px;
    border: solid #FFFFFF;
    border-width: 0 var(--arrow-size) var(--arrow-size) 0;
    display: inline-block;
    padding: var(--arrow-size);
    margin-right: -5px;
}


.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.phases {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

@keyframes flickerAnimation {
    0%   { opacity:0; }
    100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
    0%   { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
    0%   { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
    0%   { opacity:0; }
    100% { opacity:1; }
}

.example-appear {
    opacity: 0.01;
}

.example-appear.example-appear-active {
    opacity: 1;
    transition: opacity 1s ease-in;
}

.phase:hover{
    cursor: pointer;
    filter: brightness(200%);
}

h3 {
    font-weight: 400;
    color: #f0ffff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.phase-text {
    margin-top: 10px;
}

.phases-body {
    display: grid;
    grid-template-rows: 50% 50%;
    height: 20%;
}

h2 {
    vertical-align: center;
    text-align: center;
    font-weight: 200;
    font-family: FZS3, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #f0ffff;
    height: 2em;
    font-size: 1.8em;
}

.highlight-1 {
    color: #6facf1;
    font-weight: 300;
}

.highlight-2 {
    color: #54bead;
    font-weight: 300;
}

.highlight-3 {
    color: #96d681;
    font-weight: 300;
}

.highlight-4 {
    color: #cdcc7a;
    font-weight: 300;
}

.highlight-5 {
    color: #c27077;
    font-weight: 300;
}

.ph p {
    font-family: FZQ, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin-top: 10px;
    color: azure;
}

.ph {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.6em;
    font-weight: 200;
}

.link-tags {
    display: flex;
}