@font-face {
    font-family: 'Raleway';
    src: local('Raleway'), url(../raleway.extralight.ttf) format('truetype');
}
.nav h1 {
    color: azure;
    font-weight: 400;
    margin: 20px 50px;
    font-size: 1.8em;
}

.nav {
    display: flex;
    font-family: FZQ, Helvetica, sans-serif;
    justify-content: space-between;
    width: 100%;
    justify-self: center;
}

.go-to-top img{
    height: 50px;
}

.ant-tag:hover {
    cursor: pointer;
}

.no-hover:hover {
    cursor: not-allowed;
}